// import dependencies
import React from "react";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";

// import components
import Layout from "../components/layout";
import PostNavButtons from "../components/posts-nav-buttons";
import Section from "../components/section";

// import styles and assets
import * as styles from "./styles/group-feed.module.scss";

export default function TagsPage({
    data: {
        allMarkdownRemark: { group },
    },
}) {
    return (
        <Layout>
            <Section>
                <PostNavButtons />
                <div className={styles.groupContainer}>
                    <h1>Categories</h1>
                    <h4>Are you looking for something in particular?</h4>
                    <div className={styles.groupBox}>
                        <ul className={styles.groupFeed}>
                            {group.map((tag) => (
                                <li
                                    className={styles.entryBox}
                                    key={tag.fieldValue}
                                >
                                    <Link
                                        to={`/tags/${kebabCase(
                                            tag.fieldValue
                                        )}/`}
                                    >
                                        {tag.fieldValue}
                                    </Link>
                                    <p>{tag.totalCount} posts</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Section>
        </Layout>
    );
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;
